import React from 'react'
import './experience.css'
import { BsFillPatchCheckFill } from "react-icons/bs"

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>DevOps</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>AWS</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>CircleCi</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>Serverless</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>CloudFormation</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>Azure</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>Shell Scripting</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

          </div>
        </div>



        <div className="experience__backend">
        <h3>Software Development</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>yaml</h4>
                <small className='text-light'>Experienced</small>
                </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>C</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>C++</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

            <article className='experience__details'>
              <BsFillPatchCheckFill className='experience__details-icon' />
              <dev>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </dev>
            </article>

          </div>
        </div>

      </div>
    </section>
  )
}

export default Experience