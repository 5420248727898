import React from 'react'
import { FaLinkedin } from "react-icons/fa6"
import { FaGithub } from "react-icons/fa6"
import { FaSquareDribbble } from "react-icons/fa6"

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href='https://www.linkedin.com/in/anoop-ajayakumar/' target="_blank"><FaLinkedin/></a>
      <a href='https://github.com/leohack' target="_blank"><FaGithub/></a>
      <a href='https://dribble.com' target="_blank"><FaSquareDribbble/></a>
    </div>
  )
}

export default HeaderSocials
