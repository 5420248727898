import React from 'react'
import './services.css'
import { FaCheck } from "react-icons/fa6"

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Infra Design</h3>
          </div>

          <ul className='service__list'>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Infra design for www.lokyata.com </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Infra design for www.woccu.com </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Infrastructure as a code for US Bank Websites</p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Infrastructure as a code build from scratch for US lead lenders</p>
            </li>          
          </ul>
        </article>


        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Flask python bean stack development </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Server less design for Lokyata.com</p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Batch and cloud fromation design for lokyata.com </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Security and encryption design for woccu.com </p>
            </li>          
          </ul>
        </article>


        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Video and photos for design </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Ai associated design for image backups </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Disaster managemnet design and cost analysis for lokyata.com </p>
            </li>

            <li>
              <FaCheck className='service__list-icon' />
              <p>Content delivery and UI design for woccu </p>
            </li>          
          </ul>
        </article>


      </div>
    </section>
  )
}

export default Services

