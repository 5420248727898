import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'


// import Swiper core and required modules
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'


const data = [
  {
    avatar: AVTR1,
    name: 'Antony',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum consectetur sit ratione voluptates cumque enim laborum dolorem rem officia nulla!'
  },
  {
    avatar: AVTR2,
    name: 'Kevin',
    review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officiis quia possimus rem totam doloremque consequuntur dolores inventore, aut error quod.'
  },
  {
    avatar: AVTR3,
    name: 'Arthur',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam facilis unde inventore dicta! Dolorum aliquam fugiat possimus autem laborum. Fugit'
  },
  {
    avatar: AVTR4,
    name: 'Evan',
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam facilis unde inventore dicta! Dolorum aliquam fugiat possimus autem laborum. Fugit'
  },
]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }} >

        {
          data.map(({avatar, name, review}, index) => {
            return(
        

              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt=""/>
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className="client__review">{review}</small>
              </SwiperSlide>
            )
          })
        }


      </Swiper>
    </section>
  )
}

export default Testimonials
