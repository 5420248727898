/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { LiaAwardSolid } from "react-icons/lia"
import { FaUsersLine } from "react-icons/fa6"
import { GrProjects } from "react-icons/gr"

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image"/>'
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
                <LiaAwardSolid className='about_icon'/>
                <h5>Experience</h5>
                <small>3+ Years in Devops</small>
            </article>

            <article className='about__card'>
                <FaUsersLine className='about_icon'/>
                <h5>Clients</h5>
                <small>3+ Leading Clients</small>
            </article>

            <article className='about__card'>
                <GrProjects className='about_icon'/>
                <h5>Projects</h5>
                <small>10+ Devops Projects</small>
            </article>

          </div>


          <p>
          Zealous and experienced DevOps Engineer interested in disrupting technologies and innovative solutions 
          to positively metamorphose life. I'm looking for an opportunity to apply my passion, knowledge and 
          experience in a collaborative environment to attain individual as well as organizational goals.
          </p>

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About