import React from 'react'
import './footer.css'
import { BiLogoFacebookSquare } from "react-icons/bi"
import { FaInstagramSquare } from "react-icons/fa"
import { FaTwitterSquare } from "react-icons/fa"
import { TfiYoutube } from "react-icons/tfi";

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>ANOOPAJAY</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      
      <div className="footer__socials">
        <a href="https://www.facebook.com/anoop.surfer"><BiLogoFacebookSquare/></a>
        <a href="https://www.instagram.com/anoopajayofficial/"><FaInstagramSquare/></a>
        <a href="https://twitter.com/anoopajay"><FaTwitterSquare/></a>
        <a href="https://www.youtube.com/c/AnoopAjay"><TfiYoutube/></a>
      </div>
      
      <div className='footer__copyright'>
        <small>&copy; Anoop Ajay. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer