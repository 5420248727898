import React from 'react'
import './contact.css'
import { MdEmail } from "react-icons/md"
import { FaFacebookMessenger } from "react-icons/fa"
import { FaWhatsapp } from "react-icons/fa"
import { useRef } from 'react';
import emailjs from '@emailjs/browser'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_p527u1b","template_iqanqyl", form.current, {
        publicKey: "By8YKbFtUk3aW6Ei8",
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      
    e.target.reset();
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          
          <article className="contact__option">
            < MdEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>info.anoop.ajay@gmail.com</h5>
            <a href="mailto:info.anoop.ajay@gmail.com" target='_blank'>Send a Message</a>
          </article>

          <article className="contact__option">
            < FaFacebookMessenger className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>AnoopAjay</h5>
            <a href="https://m.me/anoop.surfer" target='_blank'>Send a Message</a>
          </article>

          <article className="contact__option">
            < FaWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+91 9645452011</h5>
            <a href="https://api.whatsapp.com/send/?phone=9645452011" target='_blank'>Send a Message</a>
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact